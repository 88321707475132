import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, StyleSheet, View } from 'react-native'
import { Dialog, IconButton, List, Portal, Text } from 'react-native-paper'
import { IconClose, IconContributors } from '../../../utils/meldd-icons'
import { useTheme } from '../../../hooks/use-theme'
import { AppNavigation } from '../../../navigation/types'
import { useNavigation } from '@react-navigation/native'
import { useArticleContributorsQuery } from '../../../generated/graphql'
import { Contributor } from '../types'
import { UserLink } from '../../ClusterCards/components/ClickableClusterMember'

interface DefaultBarProps {
  articleId: string
  canContribute: boolean
  ownerId?: string
  username?: string
  showPerspectiveOwners: boolean
}

export function DefaultBar(props: DefaultBarProps) {
  const { articleId } = props
  const { t } = useTranslation('contributions')
  const navigation = useNavigation<AppNavigation>()
  const theme = useTheme()
  const [show, setShow] = React.useState(false)
  const { data } = useArticleContributorsQuery({
    variables: { id: articleId },
    fetchPolicy: 'cache-and-network',
  })
  const contributors: Contributor[] = useMemo(
    () => data?.article.contributors || [],
    [data]
  )

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          height: 74,
          backgroundColor: theme.colors.surfaceVariant,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: theme.spacing(2),
        },
        hint: {
          maxWidth: '50%',
          textAlign: 'center',
          padding: theme.spacing(2),
        },
        dialog: {
          backgroundColor: theme.colors.surfaceVariant,
          borderRadius: 4,
          padding: theme.spacing(0),
        },
        title: {},
        header: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(0),
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: theme.spacing(2),
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
        },
        content: {
          paddingHorizontal: 0,
          paddingBottom: 0,
        },
        heading: {
          flex: 1,
          color: theme.colors.onSurfaceVariant,
        },
        button: {
          paddingHorizontal: theme.spacing(2),
        },
        user: {
          color: theme.colors.primary,
        },
        listItem: {
          padding: 0,
          backgroundColor: theme.colors.background,
          alignItems: 'center',
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.surfaceVariant,
          paddingRight: theme.spacing(5),
        },
        footer: {
          flexDirection: 'row',
          // margin: theme.spacing(2),
          padding: theme.spacing(4),
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        summary: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(1),
          columnGap: theme.spacing(2),
        },
      }),
    [theme]
  )
  const renderItem = ({ item }: { item: Contributor }) => {
    return (
      <List.Item
        onPress={() => {
          setShow(false)
          navigation.navigate('Profile', { userId: item.id })
        }}
        style={styles.listItem}
        title={item.username}
        titleStyle={styles.user}
      // description={item.justification}
      />
    )
  }

  function showPerspectiveOwner() {
    if (!props.showPerspectiveOwners) {
      return (
        <Text>
          {t('Perspective Owner: ')}{t('anonymous')}
        </Text>
      )
    }
    // To avoid a crash 
    if (props.ownerId && props.username) {
      return (
        <Text>
          {t('Perspective Owner: ')} <UserLink user={{ id: props.ownerId, username: props.username }} />
        </Text>
      )
    }
    return (
      <Text>
        {t('Perspective Owner: ')}{t('anonymous')}
      </Text>
    )
  }

  return (
    <View style={styles.container}>
      <IconButton
        icon={IconContributors}
        onPress={() => {
          setShow(true)
        }}
      ></IconButton>
      {showPerspectiveOwner()}
      {props.canContribute ? (
        <View style={styles.hint}>
          <Text variant={'bodySmall'} style={{ textAlign: 'center' }}>
            {t('Select text to create a contribution')}
          </Text>
        </View>
      ) : (
        <View style={styles.hint}>
          <Text variant={'bodySmall'} style={{ textAlign: 'center' }}>
            {' '}
          </Text>
        </View>
      )}
      <Portal>
        <Dialog
          style={styles.dialog}
          visible={show}
          onDismiss={() => setShow(false)}
        >
          <View style={styles.header}>
            <Text variant={'titleMedium'} style={styles.heading}>
              {t('Contributors')}
            </Text>
            <IconButton icon={IconClose} onPress={() => setShow(false)} />
          </View>
          <Dialog.Content style={styles.content}>
            <FlatList
              data={contributors}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
            />
          </Dialog.Content>
        </Dialog>
      </Portal>
    </View>
  )
}
