import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform } from 'react-native'
import Toast from 'react-native-root-toast'
import { v4 as uuidv4 } from 'uuid'
import { Env } from '../env'
import i18n from '../i18n'

const WECHAT_STATE_KEY = 'WECHAT_STATE_KEY'

export type WechatWrapper = {
  setup: () => Promise<Boolean>
  auth: (redirectPath: string) => Promise<boolean>
  isInstalled: () => Promise<boolean>
  callback: () => Promise<string>
}
/**
 * Native wrapper is never tested as Native is not being continued by know
 * The project seems easy to handle, but I got may issues building the native part `expo-native-wechat`
 */
const WechatNativeWrapper = async (wechatId: string) => {
  const { registerApp, sendAuthRequest, isWechatInstalled } = await import(
    'expo-native-wechat'
  )
  return {
    setup: async () => {
      return (await registerApp({ appid: wechatId })) || false
    },
    auth: async (_redirect: string) => {
      // We keep this secret on the browser to compare on the redirect
      const wechatState = uuidv4()
      AsyncStorage.setItem(WECHAT_STATE_KEY, wechatState)
      await sendAuthRequest({
        scope: 'snsapi_userinfo',
        state: wechatState,
      })
      return true
    },
    isInstalled: async () => {
      const result = (await isWechatInstalled()) as unknown as {
        success: boolean
      }
      return result.success
    },
    callback: () => Promise.resolve(''),
  }
}

const WechatWebWrapper = (wechatId: string) => {
  const loadWechatScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src =
        'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
      script.onload = () => resolve(true)
      script.onerror = () => reject(false)
      document.body.appendChild(script)
    })
  }
  let wxLogin: any | undefined

  // Need to be capture before on creating, after the `await` the location.search will be empty
  const urlParams = new URLSearchParams(window.location.search)
  const callbackCode = urlParams.get('code') || ''
  const callbackState = urlParams.get('state') || ''

  const isWeChatBrowser = () => {
    return /MicroMessenger/i.test(navigator.userAgent)
  }

  const desktopAuthQRCode = (path: string): Promise<boolean> => {
    try {
      const wechatState = uuidv4()
      AsyncStorage.setItem(WECHAT_STATE_KEY, wechatState)

      const options = {
        id: 'wechat-login-container',
        appid: wechatId,
        scope: 'snsapi_login',
        redirect_uri: encodeURIComponent(`${window.location.origin}/${path}`),
        state: wechatState,
        style: '',
        href: '',
      }
      wxLogin = new (window as any).WxLogin(options)

      return new Promise((resolve) => setTimeout(() => resolve(true), 500))
    } catch (err) {
      Toast.show((err as Error).toString())
      console.error('WeChat SDK failed to load', err)
      return Promise.resolve(false)
    }
  }

  const wechatRedirectAuth = (path: string) => {
    const wechatState = uuidv4()
    AsyncStorage.setItem(WECHAT_STATE_KEY, wechatState)
    const redirectUrl = encodeURIComponent(
      encodeURIComponent(`${window.location.origin}/${path}`)
    )

    const wechatUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${wechatId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=${wechatState}#wechat_redirect`
    // Remove this befefore merge
    // console.log({ wechatUrl })
    // snsapi_login snsapi_userinfo

    window.location.href = wechatUrl
    Toast.show(i18n.t('login_register_reset.redirect-wechat-login'))
    // Keep false so we don't display the modal
    return Promise.resolve(false)
  }
  return {
    setup: async () => {
      if (!(await loadWechatScript())) {
        Toast.show('NO LOADED!')

        return false
      }
      return true
    },
    callback: async () => {
      if (!callbackState || !callbackCode) {
        return ''
      }
      const savedState = (await AsyncStorage.getItem(WECHAT_STATE_KEY)) || ''
      if (callbackState !== savedState && !Env.IS_DEVELOP) {
        Toast.show('Invalid Wechat state, please try again')
        return ''
      }
      return callbackCode
    },
    auth: (path: string): Promise<boolean> => {
      if (isWeChatBrowser()) {
        return wechatRedirectAuth(path)
      }
      return desktopAuthQRCode(path)
    },
    isInstalled: () => Promise.resolve(true),
  }
}

const MockWrapper = (wechatId: string) => {
  return {
    setup: async () => {
      return false
    },
    auth: (wechatState: string, path: string): Promise<boolean> => {
      return Promise.resolve(false)
    },
    isInstalled: () => Promise.resolve(false),
    callback: () => Promise.resolve(''),
  }
}
/**
 *  An abstraction to simplify the code on the screen.
 * That loads web or native libraries accordingly
 */
export const Wechat = async (wechatId: string): Promise<WechatWrapper> => {
  if (Platform.OS !== 'web') {
    return WechatNativeWrapper(wechatId)
  }
  return WechatWebWrapper(wechatId)
}
