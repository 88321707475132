import { createStackNavigator, StackHeaderProps } from '@react-navigation/stack'
import { AppNavigatorParams } from './types'
import { ClusterListScreen } from '../screens/ClusterList/ClusterListScreen'
import WechatRedirect from '../screens/Social/WechatRedirect'
import {
  MelddNavigationBar,
  MelddNavigationBarProps,
} from '../components/MelddNavigationBar'
import {
  NodeNavigationBar,
  NodeNavigationBarProps,
} from '../components/NodeNavigationBar'
import { VisualizationNavigationBar } from '../screens/Visualization/VisualizationNavigationBar'
import {
  ProfileNavigationBar,
  ProfileNavigationBarProps,
} from '../screens/Profile/components/ProfileNavigationBar'
import {
  PerspectiveNavigationBar,
  PerspectiveNavigationBarProps,
} from '../screens/Perspective/components/PerspectiveNavigationBar'
import React from 'react'
import { AcceptInvitationScreen } from '../screens/AcceptInvitation'
import PerspectiveScreen from '../screens/Perspective/PerspectiveScreen'
import ClusterCardsScreen from '../screens/ClusterCards/ClusterCardsScreen'
const Stack = createStackNavigator<AppNavigatorParams>()

const AppNavigator = () => {
  /**
   * Lazy loads can not be access directly via URL on the web version.
   * For that do a normal import. 
   */
  const NodeStatsScreen = React.lazy(
    () => import('../screens/NodeStats/NodeStatsScreen')
  )
  const CreateArticleScreen = React.lazy(
    () => import('../screens/CreateArticle')
  )
  const UpdateArticleScreen = React.lazy(
    () => import('../screens/UpdateArticle')
  )
  const ProfileScreen = React.lazy(
    () => import('../screens/Profile/ProfileScreen')
  )
  const ProfileVerifyMobileScreen = React.lazy(
    () => import('../screens/Profile/ProfileVerifyMobileScreen')
  )
  const CreateClusterScreen = React.lazy(
    () => import('../screens/Cluster/CreateClusterScreen')
  )
  const ClusterSettingsScreen = React.lazy(
    () => import('../screens/Cluster/ClusterSettingsScreen')
  )
  const ListNodesScreen = React.lazy(
    () => import('../screens/ListNodes/ListNodesScreen')
  )
  const NodeSettingsScreen = React.lazy(
    () => import('../screens/NodeSettings/NodeSettingsScreen')
  )
  const VisualizationScreen = React.lazy(
    () => import('../screens/Visualization/VisualizationScreen')
  )
  const FeatureTogglesScreen = React.lazy(
    () => import('../screens/FeatureToggleScreen')
  )
  const PerspectiveCardsScreen = React.lazy(
    () => import('../screens/PerspectiveCards/PerspectiveCardsScreen')
  )
  const IntroScreen = React.lazy(() => import('../screens/Intro'))
  const PerspectiveContributionsScreen = React.lazy(
    () => import('../screens/Contributions/PerspectiveContributionsScreen')
  )
  const ClusterContributionsScreen = React.lazy(
    () => import('../screens/Contributions/ClusterContributionsScreen')
  )
  const DecisionsScreen = React.lazy(
    () => import('../screens/Contributions/DecisionsScreen')
  )
  const UserContributionsScreen = React.lazy(
    () => import('../screens/Contributions/UserContributionsScreen')
  )


  return (
    <Stack.Navigator
      screenOptions={{
        header: (props: StackHeaderProps) => (
          <MelddNavigationBar {...(props as MelddNavigationBarProps)} />
        ),
      }}>

      <Stack.Screen
        name="ClusterList"
        component={ClusterListScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <NodeNavigationBar {...(props as NodeNavigationBarProps)} />
          ),
        }}
      />

      <Stack.Screen name="WechatRedirect" component={WechatRedirect} options={{
        header: (props: StackHeaderProps) => (
          <NodeNavigationBar {...(props as NodeNavigationBarProps)} />
        ),
      }} />

      <Stack.Screen
        name="Visualization"
        component={VisualizationScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <VisualizationNavigationBar
              {...(props as NodeNavigationBarProps)}
            />
          ),
        }}
      />

      <Stack.Screen name="ListNodes" component={ListNodesScreen} />

      <Stack.Screen
        name="ClusterCards"
        component={ClusterCardsScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <NodeNavigationBar {...(props as NodeNavigationBarProps)} />
          ),
        }}
      />
      <Stack.Screen
        name="PerspectiveCards"
        component={PerspectiveCardsScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <NodeNavigationBar {...(props as NodeNavigationBarProps)} />
          ),
        }}
      />

      <Stack.Screen
        name="PerspectiveContributions"
        component={PerspectiveContributionsScreen}
      />
      <Stack.Screen
        name="ClusterContributions"
        component={ClusterContributionsScreen}
      />

      <Stack.Screen name="NodeStats" component={NodeStatsScreen} />
      <Stack.Screen name="CreateArticle" component={CreateArticleScreen} />
      <Stack.Screen name="UpdateArticle" component={UpdateArticleScreen} />
      <Stack.Screen name="CreateCluster" component={CreateClusterScreen} />
      <Stack.Screen
        name="Perspective"
        component={PerspectiveScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <PerspectiveNavigationBar
              {...(props as PerspectiveNavigationBarProps)}
            />
          ),
        }}
      />
      <Stack.Screen name="ClusterSettings" component={ClusterSettingsScreen} />
      <Stack.Screen name="NodeSettings" component={NodeSettingsScreen} />
      <Stack.Screen
        name="ProfileVerifyMobile"
        component={ProfileVerifyMobileScreen}
      />
      <Stack.Screen
        name="Profile"
        component={ProfileScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <ProfileNavigationBar {...(props as ProfileNavigationBarProps)} />
          ),
        }}
      />

      <Stack.Screen name="Decisions" component={DecisionsScreen} />
      <Stack.Screen name="FeatureToggles" component={FeatureTogglesScreen} />
      <Stack.Screen name="Intro" component={IntroScreen} />
      <Stack.Screen
        name="UserContributions"
        component={UserContributionsScreen}
      />
      <Stack.Screen
        name={'AcceptInvitation'}
        component={AcceptInvitationScreen}
      />
    </Stack.Navigator>
  )
}

export default AppNavigator
