import { ActivityIndicator, IconButton, Text, useTheme } from 'react-native-paper'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AuthContext } from '../../auth/auth-context'
import { WechatPNG } from '../../utils/meldd-icons'
import { Image, View } from 'react-native'

import { Env } from '../../env'
import Toast from 'react-native-root-toast'
import { MessageType } from '../../utils/message-type'
import { Wechat, WechatWrapper } from '../../utils/wechat.login'

import {
  useWechatCheckMutation,
  useWechatLoginMutation,
  useLinkWithWechatMutation
} from '../../generated/graphql'
import { WechatQRModal } from './wechat-qr.modal'
import { RegisterSSOModal, RegisterSSOModalRef } from './register-sso.modal'


export interface WechatLogin {
  callbackOnly?: boolean
  disabled?: boolean
  redirectPath?: string
  onMergeAccount?: (tokenId: string) => void
  customButton?: (props: {
    disabled?: boolean
    onPress: () => void
  }) => JSX.Element
  onSuccess?: () => void
  onLinkingError?: () => void
}

export default function WechatLogin({
  disabled,
  onMergeAccount,
  customButton,
  onSuccess,
  redirectPath,
  callbackOnly,
  onLinkingError,
}: WechatLogin) {
  const { t } = useTranslation('login_register_reset')
  const wechat = useRef<WechatWrapper>()
  const modalRef = useRef<RegisterSSOModalRef>(null)
  const { setCurrentUser } = useContext(AuthContext)
  const { userId } = useContext(AuthContext)

  const [loading, setIsLoading] = useState(false)
  const [showQrCode, setShowQrCode] = useState(false)
  const [wechatCode, setWechatCode] = useState('')

  const theme = useTheme()
  const [doCheckAccount] = useWechatCheckMutation()
  const [doWechatLogin] = useWechatLoginMutation()
  const [doLinkWithWechat] = useLinkWithWechatMutation()
  /**
   * LOAD
   */
  useEffect(() => {
    async function setup() {
      wechat.current = await Wechat(Env.WECHAT_ID)
      await wechat.current.setup()
    }
    setup()
  }, [])

  /**
   * Functions to connect with our backend
   */
  const login = async (code: string) => {
    try {
      const result = await doWechatLogin({
        variables: { input: { code } },
      })
      setCurrentUser(result.data!.wechatLogin)
      Toast.show(t('Login successful'), MessageType.info)
      onSuccess?.()
    } catch (error: unknown) {
      Toast.show((error as Error).message.toString(), MessageType.error)
    }
  }

  const check = async (code: string) => {
    let check
    try {
      check = await doCheckAccount({
        variables: { input: { code } },
      })
    } catch (error: unknown) {
      Toast.show((error as Error).message.toString(), MessageType.error)
      return
    }

    // Uncommenting this we will allow the user to create a new account or
    // link to the existing account. For now we only accept new account by simplicity
    // if (!check.data?.wechatCheck.accountExists) {
    //   modalRef.current?.open()
    //   return false
    // }
    login(code)
  }

  const link = async (code: string) => {
    try {
      await doLinkWithWechat({
        variables: { input: { code } },
      })
      Toast.show(t('Wechat Link Successfull'), MessageType.info)
      onSuccess?.()
    } catch (error: unknown) {
      Toast.show((error as Error).message.toString(), MessageType.error)
      onLinkingError?.()
    }
  }
  /**
   * REDIRECT
   */
  useEffect(() => {
    async function wechatCallback() {


      const wechat = await Wechat(Env.WECHAT_ID)
      const code = await wechat.callback() || ''
      if (!code) {
        return
      }
      setWechatCode(code)
      userId ? link(code) : check(code)
    }
    wechatCallback()
  }, [])
  /**
   * REQUEST
   */
  const wechatLogin = async () => {
    if (!wechat.current) {
      return
    }

    setIsLoading(true)
    setShowQrCode(true)

    const wechatInstalled =
      (await wechat.current?.isInstalled()) as unknown as {
        id: string
        success: boolean
      }

    if (!wechatInstalled) {
      setIsLoading(false)
      setShowQrCode(false)
      return Toast.show(t('Wechat not installed'), MessageType.error)
    }

    const result = await wechat.current.auth(redirectPath || '')
    if (!result) {
      setShowQrCode(false)
    }
    setIsLoading(false)

  }

  // The component only do callback logic, we don't render anything
  if (callbackOnly) {
    return null
  }

  // We hide on global, but allow on staging and local
  if (Env.IS_PRODUCTION && !Env.IS_CN) {
    return null
  }
  // Scanning QR code on mobile doesn't work well
  if (Env.IS_RESPONSIVE_WEB) {
    return null
  }
  return (
    <>
      {customButton ? (
        customButton({ disabled, onPress: () => wechatLogin() })
      ) : (
        <IconButton
          mode="outlined"
          icon={() =>
            loading ? (
              <ActivityIndicator size="small" color={theme.colors.primary} />
            ) : (
              <Image source={WechatPNG} style={[{ width: 16, height: 16 }]} />
            )
          }
          disabled={disabled}
          onPress={() => wechatLogin()}
        />
      )}
      <WechatQRModal
        visible={showQrCode}
        loading={loading}
        onDismiss={() => setShowQrCode(false)}
      />
      <RegisterSSOModal
        ref={modalRef}
        ssoName={t('wechat')}
        onNewAccount={() => { login(wechatCode); modalRef.current?.close() }}
        mergeAccount={() => {
          onMergeAccount?.(wechatCode)
          modalRef.current?.close()
        }}
      />
    </>
  )
}
