import * as React from 'react'
import {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { WebViewMessageEvent, WebView } from 'react-native-webview'
import { WebViewWeb, WebViewWebRef } from './WebViewWeb'
import { Toolbar } from './Toolbar'
import {
  Keyboard,
  Platform,
  RefreshControl,
  ScrollView,
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
} from 'react-native'
import { html } from '@meldd/article-editor'
import AutoHeightWebView from 'react-native-autoheight-webview'
import { useFeatures } from '../../contexts/FeatureProvider'

export type SelectionInfo = {
  start: number
  end: number
  html: string
}

export interface EditorContribution {
  id: string
  start: number
  end: number
  type: number
}

interface ArticleEditorProps {
  style?: ViewStyle
  initialContentHTML?: string
  useContainer?: boolean
  editorMode: 'edit' | 'select'
  onSelection?: (info: SelectionInfo) => void
  onContribution?: (id: string | null) => void
  onHtmlContent?: (html: string, text: string) => void
  contributions?: EditorContribution[]
  refreshing?: boolean
  onRefresh?: () => void
}

export interface ArticleEditorRef {
  requestContent: () => void
  lockSelection: (value: boolean) => void
  showContributions: (value: boolean) => void
  selectContribution: (id?: string) => void
  selectContributionChange: (change: -1 | 1) => void
  setContributions: (contributions: EditorContribution[]) => void
  setSelectionFromContribution: (contribution: EditorContribution) => void
  clearSelection: () => void
  dismissKeyboard: () => void
}

const ArticleEditorImpl = (
  props: ArticleEditorProps,
  ref: Ref<ArticleEditorRef>
) => {
  const { refreshing, onRefresh } = props
  const editorWebView = useRef<WebView | WebViewWebRef>(null)
  const messageQueue = useRef<string[]>([])
  const [viewHeight, setViewHeight] = useState(0)
  const [initialized, setInitialized] = useState(false)
  const { showEditorLogs } = useFeatures()
  const postMessage = (obj: object) => {
    const script = `onHostMessage('${JSON.stringify(obj)}')`
    if (initialized) {
      editorWebView.current?.injectJavaScript(script)
    } else {
      const strings = [...messageQueue.current, script]
      messageQueue.current = strings
    }
  }
  useEffect(() => {
    if (initialized && messageQueue.current.length) {
      for (let script of messageQueue.current) {
        editorWebView.current?.injectJavaScript(script)
      }
      messageQueue.current = []
    }
  }, [initialized])
  useImperativeHandle(
    ref,
    () => {
      return {
        requestContent: () => postMessage({ type: 'request-content' }),
        lockSelection: (value: boolean) =>
          postMessage({ type: 'lock-selection', value }),
        showContributions: (value: boolean) =>
          postMessage({ type: 'show-contributions', value }),
        selectContribution: (id?: string) =>
          postMessage({ type: 'select-contribution', id }),
        setSelectionFromContribution: (value: EditorContribution) =>
          postMessage({ type: 'set-selection', value }),
        setContributions: (contributions: EditorContribution[]) =>
          postMessage({
            type: 'set-contributions',
            contributions,
          }),
        selectContributionChange: (change: -1 | 1) =>
          postMessage({ type: 'select-contribution', change }),
        clearSelection: () => postMessage({ type: 'clear-selection' }),
        dismissKeyboard: () => {
          Keyboard.dismiss()
          // inputRef.current?.focus()
          // inputRef.current?.blur()
        },
      }
    },
    [postMessage]
  )
  const init = useMemo(
    () =>
      `window.articleEditorInit('${props.editorMode
      }', '${props.initialContentHTML
        ?.replace(/'/g, "\\'")
        .replace(/\n/g, '\\n')}', ${showEditorLogs})`,
    [props.editorMode, props.initialContentHTML]
  )

  const [editorState, setEditorState] = React.useState({})
  const onMessage = (event: WebViewMessageEvent) => {
    if (!event.nativeEvent.data || typeof event.nativeEvent.data !== 'string') {
      return
    }
    if (event.nativeEvent.data.startsWith('setImmediate$')) {
      return
    }
    const message = JSON.parse(event.nativeEvent.data) as {
      type: string
      data: any
      text?: string
    }
    switch (message.type) {
      case 'meldd-menu-bridge':
        setEditorState(message.data)
        break
      case 'meldd-selection':
        props.onSelection?.(message.data)
        break

      case 'meldd-content':
        props.onHtmlContent?.(message.data, message.text!)
        break
      case 'contribution-selected':
        props.onContribution?.(message.data || 'none')
        break
      case 'init-done':
        setInitialized(true)
        break
    }
  }
  const webOnload = useCallback(
    (...args: any[]) => {
      editorWebView.current?.injectJavaScript(init)
    },
    [init]
  )
  return (
    <View
      style={[styles.wrapper, props.style]}
      onLayout={(event) => {
        setViewHeight((val) => (!val ? event.nativeEvent.layout.height : val))
      }}>
      {props.editorMode === 'edit' && (
        <Toolbar
          editorState={editorState}
          onMenuItem={(msg) => {
            editorWebView.current?.injectJavaScript(
              `onHostMessage('${JSON.stringify(msg)}');`
            )
          }}
        />
      )}
      <ScrollView
        style={{ height: viewHeight }}
        contentContainerStyle={{ height: '100%' }}
        refreshControl={
          onRefresh && (
            <RefreshControl
              refreshing={refreshing || false}
              onRefresh={onRefresh}
            />
          )
        }>
        {Platform.OS == 'web' ? (
          <WebViewWeb
            ref={editorWebView}
            title={'Editor'}
            src={'/editor.html'}
            onLoad={webOnload}
            onMessage={onMessage}
          />
        ) : (
          <AutoHeightWebView
            hideKeyboardAccessoryView={true}
            keyboardDisplayRequiresUserAction={true}
            // nestedScrollEnabled={true}
            style={[styles.webview]}
            ref={editorWebView as any}
            onMessage={onMessage}
            originWhitelist={['*']}
            dataDetectorTypes={'none'}
            domStorageEnabled={false}
            bounces={false}
            javaScriptEnabled={true}
            source={{ html }}
            autoShowKeyboard={props.editorMode === 'edit'}
            hideSelectionContextMenu={props.editorMode !== 'edit'}
            // scalesPageToFit={true}
            customScript={init}
            viewportContent={
              'width=device-width,user-scalable=1.0,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0'
            }
          />
        )}
      </ScrollView>
    </View>
  )
}
export const ArticleEditor = forwardRef<ArticleEditorRef, ArticleEditorProps>(
  ArticleEditorImpl
)
const styles = StyleSheet.create({
  _input: {
    position: 'absolute',
    width: 1,
    height: 1,
    zIndex: -999,
    bottom: -999,
    left: -999,
  },
  wrapper: {
  },
  webview: {
    backgroundColor: 'transparent',
  },
})
