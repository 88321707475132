import {
  ActivityIndicator,
  Button,
  Dialog,
  Portal,
  Text,
  useTheme,
} from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, Platform } from 'react-native'

type RegisterSSModalProps = {
  visible: boolean
  loading: boolean
  onDismiss: () => void
}

/**
 * Display Wechat QR for web login
 */
export const WechatQRModal = ({ visible, loading, onDismiss }: RegisterSSModalProps) => {
  const { t } = useTranslation('login_register_reset')
  const theme = useTheme()

  return (
    <>
      <Portal>
        <Dialog
          visible={visible}
          onDismiss={() => onDismiss()}
          dismissable={true}
          dismissableBackButton={true}>
          <View style={styles.modalWrapper}>
            <Dialog.Title>
              <Text variant="bodyMedium">{t('Wechat Login')}</Text>
            </Dialog.Title>
            <Dialog.Content>
              {Platform.OS === 'web' ? (<>
                {loading &&
                  (<View style={styles.spinner}> <ActivityIndicator size="large" color={theme.colors.primary} /></View>)
                }
                <View id="wechat-login-container" />
              </>
              ) : (
                <ActivityIndicator size="small" color={theme.colors.primary} />
              )}
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => onDismiss()}>{t('Cancel')}</Button>
            </Dialog.Actions>
          </View>
        </Dialog>
      </Portal>
    </>
  )
}
const styles = StyleSheet.create({
  title: { fontSize: 24, fontWeight: '600' },
  spinner: { marginTop: "2em" },
  modalWrapper: {
    borderRadius: 4,
    margin: 32,
    zIndex: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
